export function pushRouter(path: string) {
    const router = useRouter();
    if (path.startsWith('http')) {
        // window.location.href = path;
        window.open(path);
    } else {
        router.push({ path });
        if (matchMobile()) allowScroll();
    }
}

export function newTab(path: string) {
    const router = useRouter();
    if (path.startsWith('http')) {
        // window.location.href = path;
        window.open(path);
    } else {
        window.open(router.resolve(path).href, '_blank');
    }
}
